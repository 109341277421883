<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('withdraw_report.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('withdraw_report.head') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input :label="$t('withdraw_report.date_from')" :date="dateFrom"
                @setValue="dateFrom = $event" />
            </span>
            <span>
              <date-picker-input :label="$t('withdraw_report.date_to')" :date="dateTo" @setValue="dateTo = $event" />
            </span>
            <v-btn color="primary" class="" @click="searchByDate"> {{ $t('search') }} </v-btn>
            <v-btn color="warning" @click="preloadExportCsv" class="">{{ $t('withdraw_report.export_excel') }}</v-btn>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="logs"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.amount`]="{ item }">
              <div class="amount-col">
                <span class="error--text"> {{ item.amount | currency }}</span>
              </div>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ formatDateSS(item.created_at) }}</span>
            </template>
            <template v-slot:[`item.coin`]="{ item }">
              <div>{{ item.coin_before }}/{{ item.coin_process }}/{{ item.coin_after }}</div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div v-if="item.status === 'success'" class="success--text">สำเร็จ</div>
              <div v-if="item.status === 'fail'" class="error--text">ไม่สำเร็จ</div>
              <div v-if="item.status === 'wait'" class="warning--text">รอดำเนินการ</div>
              <div v-if="item.status === 'cancel'" class="error--text">ยกเลิก</div>
            </template>
            <template v-slot:[`item.member.bank`]="{ item }">
              <div class="p-0 d-flex row" style="align-items: center">
                <div class="col col-12 col-lg-12 col-sm-12 p-0 img-bank">
                  <div class="w-100 ma-auto" style="width: 24px;height: 24px;aspect-ratio: 1;display: grid;">
                    <div v-if="!item.member.bankCountry">
                      <img v-if="item.member.bank" alt="bank_img" style="object-fit: contain;height: inherit;width: 100%;"
                        :src="require(`@/assets/images/logos/banks/${item.member.bank}.png`)" />
                    </div>
                    <div v-else>
                      <img v-if="item.member.bank" alt="bank_img" style="object-fit: contain;height: inherit;width: 100%;"
                        :src="`https://www.ak47.services/akasset-v2/banks/${item.member.bankCountry}/${item.member.bank}.webp`" />
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:[`item.accountTransfer`]="{ item }">
              <div v-if="item.accountTransfer" class="pa-0 d-flex row justify-center align-center">
                <div class="px-2 ma-0">
                  <div class="w-100 ma-auto" style="width: 24px;height: 24px;aspect-ratio: 1;display: grid;">
                    <div v-if="!item.accountTransfer.bankCountry">
                      <img v-if="item.accountTransfer.bank" alt="bank_img"
                        style="object-fit: contain;height: inherit;width: 100%;"
                        :src="require(`@/assets/images/logos/banks/${item.accountTransfer.bank}.png`)" />
                    </div>
                    <div v-else>
                      <img v-if="item.accountTransfer.bank" alt="bank_img"
                        style="object-fit: contain;height: inherit;width: 100%;"
                        :src="`https://www.ak47.services/akasset-v2/banks/${item.accountTransfer.bankCountry}/${item.accountTransfer.bank}.webp`" />
                    </div>
                  </div>
                </div>
                <div class="pa-0 ma-0 d-flex flex-column">
                  <div class="col pa-0">
                    <p class="detail-bank-p">{{ item.accountTransfer.bank_name && item.accountTransfer.bank_name }}</p>
                  </div>
                  <div class="col pa-0">
                    <p class="detail-bank-p">
                      {{ item.accountTransfer.bank_account && item.accountTransfer.bank_account }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      menu: false,
      showDetail: false,
      toggle_exclusive: 0,
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      searchData: null,
      headers: [
        {
          text: this.$t('withdraw_report.header1'),
          value: 'member.phone',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('withdraw_report.header2'),
          value: 'member.bank',
          width: '50px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('withdraw_report.header3'),
          value: 'member.bank_account',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('withdraw_report.header4'),
          value: 'member.bank_name',
          width: '150px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('withdraw_report.header5'),
          value: 'created_at',
          width: '130px',
          align: 'center',
          sortable: false,
        },

        {
          text: this.$t('withdraw_report.header6'),
          value: 'amount',
          width: '120px',
          align: 'right',
          sortable: false,
        },
        {
          text: this.$t('withdraw_report.header7'),
          value: 'accountTransfer',
          align: 'center',
          width: '220px',
          sortable: false,
        },
        {
          text: this.$t('withdraw_report.header8'),
          value: 'status',
          align: 'center',
          sortable: false,
          width: '100px',
        },
        {
          text: this.$t('withdraw_report.header9'),
          value: 'admin.name',
          align: 'center',
          width: '120px',
          sortable: false,
        },
      ],
      logs: [],
      search: '',
      loading: false,
      dummy: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      reportRecordLimit: 2000000,
      bankSystem: ''
    }
  },
  async created() {
    this.getWebSettingInfo()
    this.addLogPage()

  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont && !this.dummy) {
          this.loading = true
          const result = this.searchData
            ? await this.getAccountingReportData(
              `page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(
                this.dateFrom,
              )}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`,
            )
            : await this.getAccountingReportData(
              `page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(
                this.dateFrom,
              )}&time_to=${this.formatDateYYYY(this.dateTo)}`,
            )
          this.setReportData(result)
          this.loading = false
        }
        if (cont && this.dummy) {
          this.loading = true
          const result = this.searchData
            ? await this.getAccountingReportData(
              `page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(
                this.dateFrom,
              )}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`,
            )
            : await this.getAccountingReportData(
              `page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(
                this.dateFrom,
              )}&time_to=${this.formatDateYYYY(this.dateTo)}`,
            )
          this.setReportData(result)
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async getWebSettingInfo() {
      try {
        let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
        this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      } catch (e) { }
    },
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'รายงานถอน',
            url: window.location.href,
            detail: 'รายงานถอน',
            admin: userSystem.name ? userSystem.name : '',
          },
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
    formatDateSS(date) {
      return moment(date).format('YY-MM-DD HH:mm:ss')
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
    async preloadExportCsv() {
      //////// get logExportData ////////
      let logExportData
      const userSystem = store.getters.getuserInfo
      await axios.get('https://api.ipify.org?format=json').then(res =>
        (logExportData = {
          ip: res.data.ip,
          exportPage: 'รายงานถอน',
          url: window.location.href,
          detail: 'รายงานถอน',
          admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
        }),
      )

      this.$swal({
        title: `Secure code | รายงาน${logExportData.exportPage}`,
        input: 'password',
        // inputAttributes: {
        //   inputmode: 'numeric',
        //   pattern: '[0-9]*',
        //   maxlength: 6,
        // },
        inputValidator: value => {
          if (!value) {
            return 'โปรดกรอก Secure code'
          }
          // if (value && value.length !== 6) {
          //   return 'โปรดกรอกให้ครบ 6 หลัก'
          // }
        },
        inputPlaceholder: 'ใส่รหัสที่นี่...',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ออก',
      }).then(async result => {
        if (result.isConfirmed) {
          const pin = result.value
          let enterSecureData = {
            ...logExportData,
            keyCheck: pin,
          }
          this.loading = true
          let resEnterSecureData = await this.$store.dispatch('checkLogEnterSecure', enterSecureData)
          if (resEnterSecureData && resEnterSecureData.secure) {
            this.exportCsv()
          } else {
            this.$swal.fire('ไม่สามารถ download ได้ เนื่องจากรหัส PIN ไม่ถูกต้อง', '', 'error')
          }
          this.loading = false
        }
      })
    },
    async exportCsv() {
      this.$swal.fire({
        html: 'กำลังประมวลผล',
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })

      let fileName = `รายงานบัญชีถอน_${moment(new Date()).format('YYYY-MM-DD_HH-mm')}`
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'
      const wscols = [
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 40 },
        { wch: 15 },
        { wch: 15 },
      ]
      let statementlist = []
      let params = `page=1&rows=${this.reportRecordLimit}`
      this.searchData
        ? (params = `${params}&search=${this.searchData}`)
        : (params = `${params}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(
          this.dateTo,
        )}`)

      let rs = await this.$store.dispatch('getAccountingRepositWithdraw', params)
      rs.data.forEach(element => {
        statementlist.push({
          member: element.member ? String(element.member.phone) : null,
          member_bank: element.member?.bank ? element.member.bank : null,
          member_bank_account: element.member?.bank_account ? element.member.bank_account : null,
          member_bank_name: element.member?.bank_name ? element.member.bank_name : null,
          withdraw_time: moment(element.created_at).format('YYYY-MM-DD HH:mm:ss'),
          amount: Number(element.amount).toFixed(2),
          bank_transfer: element.accountTransfer
            ? element.accountTransfer.bank +
            '  ' +
            element.accountTransfer.bank_name +
            ' | ' +
            element.accountTransfer.bank_account
            : null,
          status:
            element.status === 'success'
              ? 'สำเร็จ'
              : element.status === 'fail'
                ? 'ไม่สำเร็จ'
                : element.status === 'wait'
                  ? 'รอดำเนินการ'
                  : element.status === 'cancel'
                    ? 'ยกเลิก'
                    : null,
          by: element.admin ? element.admin.name : null,
        })
      })
      let ws = XLSX.utils.json_to_sheet(statementlist)
      ws['!cols'] = wscols
      const wb = { Sheets: { AccountingWithdrawReport: ws }, SheetNames: ['AccountingWithdrawReport'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, fileName + fileExtension)

      // add log admin export
      await this.addLogExportCSV()
      this.$swal.close()
    },
    async addLogExportCSV() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'รายงานถอน',
            url: window.location.href,
            detail: 'รายงานถอน',
            admin: userSystem.name ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async searchByDate() {
      this.loading = true
      this.dummy = false
      const params = this.searchData
        ? `page=1&rows=${this.pagination.itemsPerPage}&time_from=${this.formatDateYYYY(
          this.dateFrom,
        )}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`
        : `page=1&rows=${this.pagination.itemsPerPage}&time_from=${this.formatDateYYYY(
          this.dateFrom,
        )}&time_to=${this.formatDateYYYY(this.dateTo)}`
      const result = await this.getAccountingReportData(params)
      this.setReportData(result)
      this.pagination.page = 1
      this.loading = false
    },
    async getAccountingReportData(params) {
      try {
        return await this.$store.dispatch('getAccountingRepositWithdraw', params)
      } catch (e) { }
    },
    async searchKeyword(value) {
      this.searchData = value
      this.dummy = true
      this.loading = true
      try {
        let result = {}
        if (value) {
          result = await this.getAccountingReportData(`page=1&rows=${this.pagination.rowsPerPage}&search=${value}`)
        } else {
          result = await this.getAccountingReportData(`page=1&rows=${this.pagination.rowsPerPage}`)
        }
        this.setReportData(result)
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      this.loading = false
      this.pagination.page = 1
    },
    setReportData(data) {
      data.data.forEach((element) => {
        let splitCountry, splitBankCode;
        if (element.member && element.member?.bank_code) {
          const code = element.member.bank_code;
          const checkCountryCode = (code) => {
            const validCodes = ['TH', 'KR', 'LA', 'TW', 'CN', 'KH', 'VN', 'MM'];
            const firstTwoChars = code.substring(0, 2);
            return validCodes.includes(firstTwoChars);
          }

          if (checkCountryCode(code)) {
            splitCountry = code.slice(0, 2);
            splitBankCode = code.slice(2);
          } else {
            splitCountry = this.bankSystem;
            splitBankCode = code;
          }

          element.member.bankCountry = splitCountry.toLowerCase();
          element.member.bank = splitBankCode;
        }

        if (element.accountTransfer && element.accountTransfer?.bank_code) {
          const code = element.accountTransfer.bank_code;
          splitCountry = code.slice(0, 2).toLowerCase();
          splitBankCode = code.slice(2);

          element.accountTransfer.bankCountry = splitCountry
          element.accountTransfer.bank = splitBankCode
        }
      })

      this.logs = data.data
      this.pagination.totalItems = data.count
    }
  },
}
</script>

<style scoped>
.img-bank {
  margin-top: 0px;
  padding: 0 !important;
}

.detail-bank {
  margin-top: 0px;
  padding: 0 !important;
}

.detail-bank-p {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  text-align: left;
}

@media (max-width: 550px) {
  .img-bank {
    margin-top: 20px;
  }

  .detail-bank {
    margin-top: 0px;
    padding-bottom: 15px !important;
  }

  .detail-bank-p {
    text-align: center;
  }

  .amount-col span i {
    position: relative;
    margin-left: 0;
  }
}
</style>
